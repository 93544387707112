import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PaginatedDataResponse} from '../../models/respose/PaginatedDataResponse';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {
  page = 1;
  // tslint:disable-next-line:no-input-rename
  @Input('paginatedResponse') paginatedResponse: PaginatedDataResponse<any> = new PaginatedDataResponse<any>(1, [], 1, 100, 100);
  @Output() pageChangeEvent = new EventEmitter<number>();
  constructor() { }

  changePage(page: number): void{
    this.pageChangeEvent.emit(page);
  }

  ngOnInit(): void {
  }

}
