<div [formGroup]="form">
  <label for="{{dictionary}}">{{label}}</label>
  <div class="d-flex">
    <ng-select
      class="select-dictionary"
      id="{{dictionary}}"
      bindLabel="{{dictionary}}"
      (open) = "open()"
      formControlName="{{control}}">
      <ng-option *ngFor="let element of elements" [value]="element.id">
        {{element.name}}
      </ng-option>
    </ng-select>
    <button *ngIf="enableEdit" type="button" class="btn btn-primary btn-sm ml-2" (click)="create()"><i class="fas fa-edit"></i></button>
  </div>
  <app-input-errors [element]="form.get(control)"></app-input-errors>
</div>
