<form class="p-3 form-search" [formGroup]="form">
  <div class="row">
    <div class="col-10">
      <div class="row">
        <div class="col-md-4">
          <div class="form-group">
            <label for="name">Nome</label>
            <input id="name" type="text" class="form-control" placeholder="Nome" formControlName="name">
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label for="province_search">Sede</label>
            <ng-select
              notFoundText=""
              id="province_search"
              bindLabel="province"
              (open) = "updateProvinces()"
              formControlName="province_id">
              <ng-option *ngFor="let province of provinces" [value]="province.id">
                {{province.name}}
              </ng-option>
            </ng-select>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label for="job_offer_area_search">Area Aziendale</label>
            <ng-select
              notFoundText=""
              id="job_offer_area_search"
              bindLabel="job_offer_area"
              (open) = "updateJobOfferAreas()"
              formControlName="job_offer_area_id">
              <ng-option *ngFor="let jobOfferArea of jobOfferAreas" [value]="jobOfferArea.id">
                {{jobOfferArea.name}}
              </ng-option>
            </ng-select>
          </div>
        </div>
      </div>
    </div>
    <div class="col-2">
        <div class="search-buttons d-flex align-content-end flex-wrap">
          <div>
            <button type="button" class="btn btn-primary" (click)="clear()"><fa-icon [icon]="faEraser"></fa-icon></button>
            <button type="submit" class="btn btn-primary ms-2" (click)="onSubmit()">Cerca</button>
          </div>
        </div>
    </div>
  </div>
</form>




