<ngb-pagination
  class="d-flex justify-content-end"
  [(page)]="paginatedResponse.current_page"
  [pageSize]="paginatedResponse.per_page"
  [collectionSize]="paginatedResponse.total"
  (pageChange)=changePage($event)
  [maxSize]="4"
  [rotate]="true"
>
</ngb-pagination>
