import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {JobOffersComponent} from './pages/job-offers/job-offers.component';
import {InsertCurriculumComponent} from './pages/insert-curriculum/insert-curriculum.component';
import {JobOfferDetailComponent} from './components/job-offer-detail/job-offer-detail.component';

const routes: Routes = [
  { path: 'job-offers', component: JobOffersComponent },
  { path: 'job-offers/:id', component: JobOfferDetailComponent },
  { path: 'insert-curriculum/:jobOfferId', component: InsertCurriculumComponent },
  { path: '', redirectTo: 'job-offers', pathMatch: 'full'},
  { path: '**', redirectTo: 'job-offers', pathMatch: 'full'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
