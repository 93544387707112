import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {HttpService} from './http.service';
import {JobOffer} from '../models/JobOffer';
import {BaseResponse} from '../models/respose/BaseResponse';
import {Order} from '../models/Order';
import {PaginatedDataResponse} from '../models/respose/PaginatedDataResponse';
import {PaginatedDataRequest} from '../models/request/PaginatedDataRequest';
import {JsonLdService} from 'ngx-seo';

@Injectable({
  providedIn: 'root'
})
export class JobOfferDetailJsonLdService{
  constructor(private readonly jsonLdService: JsonLdService) {
  }
  setJobOffer(jobOffer: JobOffer): void{
    const organizationObject = this.jsonLdService.getObject('Organization', {
      name: 'C.S.F. Centro Servizi e Formazione Srl',
      sameAs: 'https://www.csfapl.it/',
      logo: 'https://www.csfapl.it/images/FaccinoCSF_small.png',
    }, null);
    const addressCountryObject = this.jsonLdService.getObject('Country', {
      name: 'IT'
    }, null);
    const addressObject = this.jsonLdService.getObject('PostalAddress', {
      streetAddress: jobOffer?.address,
      addressLocality: jobOffer?.city?.name,
      addressCountry: addressCountryObject,
      addressRegion: jobOffer?.city?.province?.name,
      postalCode: jobOffer?.postal_code
    }, null);
    const jobLocationObject = this.jsonLdService.getObject('Place', {
      address: addressObject
    }, null);
    const quantitativeValueObject = this.jsonLdService.getObject('QuantitativeValue',{
      value : jobOffer?.salary,
      unitText: 'YEAR'
    });
    const baseSalaryObject = this.jsonLdService.getObject('MonetaryAmount',{
      currency: 'EUR'
    });
    const jsonLdObject = this.jsonLdService.getObject('JobPosting', {
      title: jobOffer?.name,
      name: jobOffer?.name,
      description: jobOffer?.description,
      datePosted: jobOffer?.start_date,
      emplymentType: jobOffer?.contract_type?.name,
      industry: jobOffer?.company?.company_activity?.name,
      hiringOrganization: organizationObject,
      jobLocation: jobLocationObject,
      baseSalary: baseSalaryObject
    });
    this.jsonLdService.setData(jsonLdObject);
  }
}
