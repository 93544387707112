import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {HttpService} from './http.service';
import {Order} from '../models/Order';
import {PaginatedDataResponse} from '../models/respose/PaginatedDataResponse';
import {BaseResponse} from '../models/respose/BaseResponse';
import {PaginatedDataRequest} from '../models/request/PaginatedDataRequest';
import {Curriculum} from '../models/Curriculum';
import {CurriculumRequest} from '../models/request/CurriculumRequest';
import {CurriculumInsertRequest} from '../models/request/CurriculumInsertRequest';

@Injectable({
  providedIn: 'root'
})
export class CurriculumService extends HttpService {

  constructor(http: HttpClient) {
    super(http);
  }

  getPaginatedList(entries: number,
                   page: number,
                   data?: CurriculumRequest,
                   order?: Order): Observable<BaseResponse<PaginatedDataResponse<Curriculum>>>{
    const request = new PaginatedDataRequest<CurriculumRequest>(entries, page, data, order);
    return this.post('curricula', request).pipe(
      map(response => {
        return response;
      })
    );
  }

  register(request: CurriculumInsertRequest): Observable<BaseResponse<Curriculum>>{
    return this.post('curricula/store', request);
  }

}
