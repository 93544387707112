import {Order} from '../Order';

export class PaginatedDataRequest<T>{
    entries: number;
    page: number;
    data: T | undefined;
    order: Order | undefined;

  constructor(entries: number, page: number, data: T | undefined, order?: Order | undefined){
        this.entries = entries;
        this.page = page;
        this.data = data;
        this.order = order;
    }
}
