<div class="content">
  <app-back (backClicked)="backClicked()"></app-back>
  <div class="p-4 row" *ngIf="showJobOffer">
    <div class="col">
      <img *ngIf="jobOffer?.picture" class="fa-pull-right" [src]="jobOffer?.picture" width="50%">
      <div *ngIf="!jobOffer?.company">Il CSF Centro Servizi e Formazione Srl, Agenzia per il Lavoro accreditata al Ministero del Lavoro e delle Politiche Sociali, ricerca per ampliamento del proprio organico</div>
      <div *ngIf="jobOffer?.company">Il CSF Centro Servizi e Formazione Srl, Agenzia per il Lavoro accreditata al Ministero del Lavoro e delle Politiche Sociali, ricerca per aziende clienti operanti nel settore: {{jobOffer?.company?.company_activity?.name}}</div>
      <h1 class="mt-2">{{jobOffer?.name}}</h1>
      <div [innerHTML]="jobOffer?.description"></div>
      <h5 class="mt-2">Requisiti</h5>
      <div [innerHTML]="jobOffer?.requirements"></div>
      <div [innerHTML]="jobOffer?.additional_requirements"></div>
      <div class="d-flex"><h5>Sede di lavoro:&nbsp;</h5><div>{{jobOffer?.city?.name}}</div></div>
      <div class="d-flex"><h5>Tipo di contratto:&nbsp;</h5><div>{{jobOffer?.contract_type?.name}}</div></div>
      <div class="d-flex"><h5>Settore:&nbsp;</h5><div>{{jobOffer?.job_offer_area?.name}}</div></div>
      <div [innerHTML]="jobOffer?.benefit"></div>
      <div class="mt-4">
      <p>Verranno presi in considerazione SOLO i curricula coerenti con la posizione richiesta.</p>
      <p>Il presente annuncio è rivolto ad entrambi i sessi, ai sensi delle leggi 903/77 e 125/91 e a persone di tutte le età e tutte le nazionalità, ai sensi dei decreti legislativi 215/03 e 216/03. <br>Si invitano gli interessati, prima di inviare le candidature, di consultare l’informativa sulla privacy (Regolamento UE 679/2016 - D. Lgs. 196/2003)</p>
      </div>
      <div class="row">
        <div class="col text-end m-2 text-center">
          <button type="submit" class="btn btn-primary btn-lg ms-2" (click)="sendCV(jobOffer)">Candidati per questa offerta di lavoro</button>
        </div>
      </div>
    </div>
  </div>
</div>
