<div class="title">
  <h2>{{jobOffer?.id == 1 ? 'Invia Curriculum' : jobOffer?.name}}</h2>
</div>
<form (ngSubmit)="onSubmit(form)" novalidate [formGroup]="form">
  <div class="card">
    <div class="card-header">
      Dati Anagrafici
    </div>
    <input type="hidden" formControlName="id">
    <div class="card-body">
      <div class="row m-3">

        <div class="col-md-6">
          <div class="form-group">
            <label for="first_name" >Nome</label>
            <input id="first_name" type="text" class="form-control" [ngClass]="getInvalidClass('first_name')" placeholder="Nome" formControlName="first_name">
          </div>
          <app-input-errors [element]="form.get('first_name')"></app-input-errors>
        </div>

        <div class="col-md-6">
          <div class="form-group">
            <label for="last_name">Cognome</label>
            <input id="last_name" type="text" class="form-control" [ngClass]="getInvalidClass('last_name')"  placeholder="Cognome" formControlName="last_name">
          </div>
          <app-input-errors [element]="form.get('last_name')"></app-input-errors>
        </div>

      </div>

      <div class="row m-3">
        <div class="col-md-6">
          <div class="form-group">
            <label for="gender_search">Sesso</label>
            <ng-select bindLabel="gender" id="gender_search" formControlName="gender_id">
              <ng-option *ngFor="let gender of genders" [value]="gender.id">
                {{gender.name}}
              </ng-option>
            </ng-select>
            <app-input-errors [element]="form.get('gender')"></app-input-errors>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="fiscal_code_search" >Codice Fiscale</label>
            <input id="fiscal_code_search" type="text" class="form-control" [ngClass]="getInvalidClass('fiscal_code')" placeholder="Codice Fiscale" formControlName="fiscal_code">
          </div>
          <app-input-errors [element]="form.get('fiscal_code')"></app-input-errors>
        </div>
      </div>

      <div class="row m-3">

        <div class="col-md-6">
          <div class="form-group">
            <label for="birth_date" >Data di nascita</label>
            <input id="birth_date" type="date" class="form-control" max="9999-12-31" placeholder="Data di nascita" formControlName="birth_date">
            <app-input-errors [element]="form.get('birth_date')"></app-input-errors>
          </div>
        </div>

        <div class="col-md-6">
          <div class="form-group">
            <label for="email" >Email</label>
            <input id="email" type="text" class="form-control" [ngClass]="getInvalidClass('email')" placeholder="Email" formControlName="email">
          </div>
          <app-input-errors [element]="form.get('email')"></app-input-errors>
        </div>

      </div>
      <div class="row m-3">
        <div class="col-md-6">
          <div class="form-group">
            <label for="nations">Cittadinanza</label>
            <ng-select
              id="nations"
              bindLabel="nation"
              (open) = "updateNations()"
              formControlName="nation_id">
              <ng-option *ngFor="let nation of nations" [value]="nation.id">
                {{nation.name}}
              </ng-option>
            </ng-select>
            <app-input-errors [element]="form.get('nation_id')"></app-input-errors>
          </div>
        </div>

        <div class="col-md-6">
          <div class="form-group">
            <label for="mobile_number" i18n="numero di telefono form creazione curriculum">Telefono</label>
            <input id="mobile_number" type="text" class="form-control" [ngClass]="getInvalidClass('mobile_number')" i18n-placeholder="placeholder numero di telefono form creazione curriculum" placeholder="Numero di telefono" formControlName="mobile_number">
          </div>
          <app-input-errors [element]="form.get('mobile_number')"></app-input-errors>
        </div>
      </div>
      <div class="row m-3">
        <div class="col-md-6">
          <div class="form-group">
            <label for="first_name">Provincia Domicilio</label>
            <ng-select
              bindLabel="province"
              formControlName="province_id"
              (open) = "updateProvinces()"
              (change) = "updateCities($event)"
            >
              <ng-option *ngFor="let province of provinces" [value]="province.id">
                {{province.name}}
              </ng-option>
            </ng-select>
          </div>
        </div>

        <div class="col-md-6">
          <div class="form-group">
            <label for="first_name">Comune Domicilio</label>
            <ng-select
              bindLabel="city"
              formControlName="city_id">
              <ng-option *ngFor="let city of cities" [value]="city.id">
                {{city.name}}
              </ng-option>
            </ng-select>
            <app-input-errors [element]="form.get('city_id')"></app-input-errors>
          </div>
        </div>

      </div>

    </div>


  </div>
  <div class="card">
    <div class="card-header">
      Studi
    </div>
    <div class="card-body">
      <div class="row m-3">

        <div class="col-md-6">
          <div class="form-group">
            <label for="first_name">Titolo di studio</label>
            <ng-select
              bindLabel="study_certificate"
              (open) = "updateStudyCertificates()"
              formControlName="study_certificate_id">
              <ng-option *ngFor="let studyCertificate of studyCertificates" [value]="studyCertificate.id">
                {{studyCertificate.name}}
              </ng-option>
            </ng-select>
            <app-input-errors [element]="form.get('study_certificate_id')"></app-input-errors>
          </div>
        </div>

        <div class="col-md-6">
          <div class="form-group">
            <label for="first_name">Materia di studio</label>
            <ng-select
              bindLabel="subject"
              (open) = "updateSubjects()"
              formControlName="subject_id">
              <ng-option *ngFor="let subject of subjects" [value]="subject.id">
                {{subject.name}}
              </ng-option>
            </ng-select>
            <app-input-errors [element]="form.get('subject_id')"></app-input-errors>
          </div>
        </div>

      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-header">
      Esperienze lavorative
    </div>
    <div class="card-body">
      <ng-container formArrayName="work_experiences">
        <ng-container *ngFor="let workExperienceForm of work_experiences.controls; let i = index">
          <div class="work-experience-row" [formGroupName]="i">
            <div class="row m-3">
              <div class="col-md-4">
                <div class="form-group">
                  <div class="form-group">
                    <label>Azienda</label>
                    <input type="text" class="form-control" placeholder="Azienda" formControlName="company">
                    <app-input-errors [element]="getWorkExperienceForm(i).get('company')"></app-input-errors>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label>Mansione</label>
                  <input type="text" class="form-control" placeholder="Mansione" formControlName="task">
                  <app-input-errors [element]="getWorkExperienceForm(i).get('task')"></app-input-errors>
                </div>
              </div>
              <div class="col-md-4">
                <app-select-dictionary [enableEdit]="false" [label]="'Tipo di Contratto'" [dictionary]="'contract_type'" [form]="getWorkExperienceForm(i)"></app-select-dictionary>
              </div>
            </div>
            <div class="row m-3">
              <div class="col-md-5">
                <div class="form-group">
                  <label>Data di inizio esperienza</label>
                  <input type="date" class="form-control" max="9999-12-31" formControlName="start_date">
                  <app-input-errors [element]="getWorkExperienceForm(i).get('start_date')"></app-input-errors>
                </div>
              </div>
              <div class="col-md-5">
                <div class="form-group">
                  <label>Data di fine esperienza</label>
                  <input type="date" class="form-control" max="9999-12-31" formControlName="end_date">
                  <app-input-errors [element]="getWorkExperienceForm(i).get('end_date')"></app-input-errors>
                </div>
              </div>
              <div class="col-md-2 text-end mt-3">
                <button class="btn btn-danger" (click)="deleteWorkExperience(i)">-</button>
              </div>
            </div>
          </div>
          <hr>
        </ng-container>
      </ng-container>
      <div class="row">
        <div class="col text-center">
          <button class="btn btn-primary" (click)="addWorkExperience()">
            +
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-header">
      Parametri aggiuntivi
    </div>
    <div class="card-body">
      <div class="row" *ngFor="let param of checkboxTags">
        <div class="col">
          <div class="form-group">
            <label>{{param.name | titlecase}}</label>
            <input type="checkbox" class="m-lg-2" name="tag_{{param.id}}" (change)="setCheckboxes($event)">
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="card">
    <div class="card-header">
      Curriculum
    </div>
    <div class="card-body">
      <div class="row m-3">
        <div class="col-6">
          <div class="form-group">
            <label for="curriculum"  >Curriculum</label>
            <app-input-file id="curriculum" [form]="form" [controlName]="'curriculum'" [fileTypes]="pdfFileTypes"></app-input-file>
            <span>(Supportato formato .pdf, massimo {{getTextSize(max_file_size_kb)}})</span>
            <app-input-errors [element]="form.get('curriculum')"></app-input-errors>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label for="picture" >Foto</label>
            <app-input-file id="picture" [form]="form" [controlName]="'picture'" [fileTypes]="imageFileTypes"></app-input-file>
            <span>(Supportati formati .png, .jpg, .jpeg, .tiff, massimo {{getTextSize(max_file_size_kb)}})</span>
            <app-input-errors [element]="form.get('picture')"></app-input-errors>
          </div>
        </div>
      </div>
      <div class="row m-3">
        <div class="col">
          <div class="form-group">
            <label for="cover_letter" >Lettera di presentazione</label>
            <angular-editor id="cover_letter" [ngClass]="getInvalidClass('cover_letter')" formControlName="cover_letter" [config]="editorConfig"></angular-editor>
            <app-input-errors [element]="form.get('cover_letter')"></app-input-errors>
          </div>
        </div>
      </div>

    </div>
  </div>
  <div class="card">
    <div class="card-body">
      <div class="form-check mt-2 mb-2">
        <input (change)="buttonDisabled = !buttonDisabled" class="form-check-input" type="checkbox" value="" id="privacyCheck">
        <label class="form-check-label" for="privacyCheck">
          Ho preso visione dell’<a href="https://www.csf-formazione.it/informativa-sulla-privacy.html" target="_blank">informativa sulla privacy</a> e Autorizzo il trattamento dei dati personali presenti nel CV in conformità con l’art. 13 del Decreto Legislativo 30 giugno 2003, n. 196 (Codice in materia di protezione dei dati personali) e dell’articolo 13 del GDPR (Regolamento UE 2016/679)
        </label>
      </div>
    </div>
    <div class="card-footer text-center">
      <button [disabled]="buttonDisabled" type="submit" class="btn btn-primary btn-lg" >Invia Candidatura</button>
    </div>
  </div>
</form>
