import {Component, Injector, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {DictionaryModalService} from '../../services/dictionaryModal.service';
import {Dictionary} from '../../models/Dictionary';
import {DictionaryListConstants} from '../../constants/DictionaryListConstants';

@Component({
  selector: 'app-select-dictionary',
  templateUrl: './select-dictionary.component.html',
  styleUrls: ['./select-dictionary.component.css']
})
export class SelectDictionaryComponent implements OnInit {

  @Input() enableEdit = true;
  @Input() label = '';
  @Input() dictionary = '';
  @Input() control = '';
  @Input() public form!: FormGroup;
  @Input() createComponent: any;

  private service: any;
  elements: Dictionary[] = [];

  constructor(
    private injector: Injector,
    private dictionaryModalService: DictionaryModalService
  ) {
  }

  ngOnInit(): void {
    this.service = this.injector.get<any>(DictionaryListConstants[this.dictionary as keyof typeof DictionaryListConstants]);
    if (this.control === ''){
      this.control = this.dictionary + '_id';
    }
    if (this.form.get(this.control)?.value){
      this.updateElements();
    }
  }

  open(): void{
    if (this.elements.length === 0) {
        this.updateElements();
    }
  }

  updateElements(): void{
    this.service.getAll().subscribe((result: any) => {
      this.elements = result.data;
    });
  }

  create(): void {
    this.dictionaryModalService.openModal(this.createComponent).result.then(() => {
    }, () => {
      this.updateElements();
    });
  }
}
