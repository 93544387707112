<nav class="navbar navbar-expand-lg navbar-light bg-light mt-2 navbar-container">
  <div class="container-fluid menu-container">
    <div class="collapse navbar-collapse" id="navbarNavDropdown">
      <ul class="navbar-nav fw-bold">
        <li class="nav-item ms-3 me-3">
          <a href="https://csfbusiness.it/" class="nav-link">Home</a>
        </li>
        <li class="nav-item ms-3 me-3">
          <a routerLink="./job-offers" routerLinkActive="active" class="nav-link">Annunci</a>
        </li>
        <li class="nav-item ms-3 me-3">
          <a routerLink="./insert-curriculum/1" routerLinkActive="active" class="nav-link">Invia il tuo Curriculum</a>
        </li>
        <!--
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Annunci
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
            <li class="dropdown-element"><a class="dropdown-item" href="#">Posizioni aperte</a></li>
          </ul>
        </li>
        -->
        <li class="nav-item">
          <a class="nav-link" href="#">Il mio account</a>
        </li>
      </ul>
    </div>
  </div>
</nav>
