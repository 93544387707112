import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {BaseResponse} from '../models/respose/BaseResponse';
import {ExperienceYear} from '../models/ExperienceYear';
import {Order} from '../models/Order';
import {PaginatedDataResponse} from '../models/respose/PaginatedDataResponse';
import {PaginatedDataRequest} from '../models/request/PaginatedDataRequest';
import {HttpService} from './http.service';

@Injectable({
  providedIn: 'root'
})
export class ExperienceYearService extends HttpService {

  constructor(http: HttpClient) {
    super(http);
  }

  getAll(): Observable<BaseResponse<ExperienceYear[]>>{
    return this.get('experience_years');
  }

}
