import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Province} from '../../models/Province';
import {JobOfferArea} from '../../models/JobOfferArea';
import {JobOfferAreaService} from '../../services/job-offer-area.service';
import {ProvinceService} from '../../services/province.service';
import {faEraser} from '@fortawesome/free-solid-svg-icons/faEraser';

@Component({
  selector: 'app-form-search',
  templateUrl: './form-search.component.html',
  styleUrls: ['./form-search.component.scss']
})
export class FormSearchComponent implements OnInit {
  public form: FormGroup;
  public provinces: Province[] = [];
  public jobOfferAreas: JobOfferArea[] = [];
  @Output() search = new EventEmitter<FormGroup>();
  faEraser = faEraser;
  constructor(
    private fb: FormBuilder,
    private jobOfferAreaService: JobOfferAreaService,
    private provinceService: ProvinceService,
  ) { }

  ngOnInit(
  ): void {
    this.form = this.fb.group({
      name: [''],
      province_id: [''],
      job_offer_area_id: [''],
    });
    this.search.emit(this.form);
  }
  updateProvinces(): void {
    if (this.provinces.length === 0) {
      this.provinceService.getAll().subscribe(result => {
        this.provinces = result.data;
      });
    }
  }
  updateJobOfferAreas(): void {
    if (this.jobOfferAreas.length === 0) {
      this.jobOfferAreaService.getAll().subscribe(result => {
        this.jobOfferAreas = result.data;
      });
    }
  }
  clear(): void{
    this.form.reset();
    this.onSubmit();
  }
  onSubmit(): void{
    this.search.emit(this.form);
  }
}
