import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {BaseResponse} from '../models/respose/BaseResponse';
import {City} from '../models/City';
import {HttpService} from './http.service';

@Injectable({
  providedIn: 'root'
})
export class CityService extends HttpService {

  constructor(http: HttpClient) {
    super(http);
  }

  getAll(province_id: number): Observable<BaseResponse<City[]>>{
    return this.get('cities/' + province_id);
  }
}
