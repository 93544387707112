import { Component, OnInit } from '@angular/core';
import {JobOfferService} from '../../services/jobOffer.service';
import {PaginatedDataResponse} from '../../models/respose/PaginatedDataResponse';
import {JobOffer} from '../../models/JobOffer';
import {Order} from '../../models/Order';
import {DirectionType} from '../../enum/DirectionType';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Router} from '@angular/router';

@Component({
  selector: 'app-job-offers',
  templateUrl: './job-offers.component.html',
  styleUrls: ['./job-offers.component.scss']
})
export class JobOffersComponent implements OnInit {
  public paginatedResponse: PaginatedDataResponse<JobOffer> = new PaginatedDataResponse<JobOffer>(0, [], 0, 0, 0);
  private order: Order = new Order('id', DirectionType.desc);
  private formSearch: FormGroup;
  public jobOffer: JobOffer;
  public showList = true;
  public showForm = false;
  public showDetail = false;
  public showBack = false;

  constructor(
    private jobOfferService: JobOfferService,
    private router: Router,
  ) { }

  ngOnInit(): void {
  }
  search(formSearch: FormGroup, page: number = 1): void{
    this.formSearch = formSearch;
    this.jobOfferService.getPaginatedList(10,
      page,
      formSearch.value,
      this.order).subscribe(result => this.paginatedResponse = result.data);
  }
  updatePage(page: number): void{
    this.search(this.formSearch, page);
  }
  backClicked(): void{
    this.showList = true;
    this.showForm = false;
    this.showDetail = false;
    this.showBack = false;
  }
  jobOfferSelected(jobOffer: JobOffer): void{
    this.router.navigate(['/job-offers', jobOffer.id]);
  }
  sendCurriculumClicked(jobOffer: JobOffer): void{
    this.showList = false;
    this.showForm = true;
    this.showDetail = false;
    this.showBack = true;
  }
}
