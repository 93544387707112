import { Component, OnInit } from '@angular/core';
import {JobOfferService} from '../../services/jobOffer.service';
import {JobOffer} from '../../models/JobOffer';
import {CurriculumService} from '../../services/curriculum.service';
import {CurriculumInsertRequest} from '../../models/request/CurriculumInsertRequest';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-insert-curriculum',
  templateUrl: './insert-curriculum.component.html',
  styleUrls: ['./insert-curriculum.component.scss']
})
export class InsertCurriculumComponent implements OnInit {

  public jobOffer: JobOffer;

  constructor(
    public jobOfferService: JobOfferService,
    public curriculumService: CurriculumService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.jobOfferService.getItem(+params.jobOfferId).subscribe(result => this.jobOffer = result.data);
    });
  }
  saveCurriculum(curriculum: CurriculumInsertRequest): void{
    this.curriculumService.register(curriculum).subscribe(() => {
      this.router.navigate(['/job-offers']);
    });
  }


}
