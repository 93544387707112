import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {JobOffer} from '../../models/JobOffer';
import {ActivatedRoute, Router} from '@angular/router';
import {JobOfferService} from '../../services/jobOffer.service';
import {CurriculumRequest} from '../../models/request/CurriculumRequest';
import {CurriculumService} from '../../services/curriculum.service';
import {JsonLdService} from 'ngx-seo';
import {JobOfferDetailJsonLdService} from '../../services/jobOfferDetailJsonLd.service';

@Component({
  selector: 'app-job-offer-detail',
  templateUrl: './job-offer-detail.component.html',
  styleUrls: ['./job-offer-detail.component.scss']
})
export class JobOfferDetailComponent implements OnInit {
  public jobOffer: JobOffer;
  @Output() clickSendCurriculum = new EventEmitter<JobOffer>();
  public id: number;
  public showJobOffer = true;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private jobOfferService: JobOfferService,
    private curriculumService: CurriculumService,
    private jobOfferDetailJsonLdService: JobOfferDetailJsonLdService,
  ) {
    this.route.params.subscribe(params => {
      this.id = +params.id;
      this.jobOfferService.getItem(+params.id).subscribe(result => {
        this.jobOffer = result.data;
        this.jobOfferDetailJsonLdService.setJobOffer(this.jobOffer);
      });
    });

  }

  ngOnInit(): void {
  }

  sendCV(jobOffer: JobOffer): void{
    this.router.navigate(['/insert-curriculum', jobOffer.id]);
  }
  backClicked(): void{
    this.router.navigate(['/job-offers']);
  }
  saveCurriculum(curriculum: CurriculumRequest): void{
    this.curriculumService.register(curriculum).subscribe(() => {
      this.router.navigate(['/job-offers']);
    });
  }
}
