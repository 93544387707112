import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {JobOffer} from '../../models/JobOffer';
import {PaginatedDataResponse} from '../../models/respose/PaginatedDataResponse';

@Component({
  selector: 'app-offers-list',
  templateUrl: './offers-list.component.html',
  styleUrls: ['./offers-list.component.scss']
})
export class OffersListComponent implements OnInit {
  @Input() paginatedResponse: PaginatedDataResponse<JobOffer>;
  @Output() jobOfferSelected = new EventEmitter<JobOffer>();
  @Output() updatePageEvent = new EventEmitter<number>();
  constructor() { }

  ngOnInit(): void {
  }
  selectJobOffer(jobOffer: JobOffer): void{
    this.jobOfferSelected.emit(jobOffer);
  }
  updatePage(page: number = 1): void{
    this.updatePageEvent.emit(page);
  }

}
