import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {HttpService} from './http.service';
import {BaseResponse} from '../models/respose/BaseResponse';
import {Province} from '../models/Province';

@Injectable({
  providedIn: 'root'
})
export class ProvinceService extends HttpService {

  constructor(http: HttpClient) {
    super(http);
  }

  getAll(): Observable<BaseResponse<Province[]>>{
    return this.get('provinces');
  }
}
