import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {HttpService} from './http.service';
import {BaseResponse} from '../models/respose/BaseResponse';
import {Tag} from '../models/Tag';

@Injectable({
  providedIn: 'root'
})
export class TagService extends HttpService {

  constructor(http: HttpClient) {
    super(http);
  }

  getCheckboxTags(): Observable<BaseResponse<Tag[]>>{
    return this.get('tags/checkbox');
  }

}
