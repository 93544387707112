import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {HttpService} from './http.service';
import {BaseResponse} from '../models/respose/BaseResponse';
import {JobOfferArea} from '../models/JobOfferArea';

@Injectable({
  providedIn: 'root'
})
export class JobOfferAreaService extends HttpService {

  constructor(http: HttpClient) {
    super(http);
  }

  getAll(): Observable<BaseResponse<JobOfferArea[]>>{
    return this.get('job_offer_areas');
  }
}
