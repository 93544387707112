import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { HomeComponent } from './pages/home/home.component';
import { FormSearchComponent } from './components/form-search/form-search.component';
import { OffersListComponent } from './components/offers-list/offers-list.component';
import { FooterComponent } from './components/footer/footer.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { JobOffersComponent } from './pages/job-offers/job-offers.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgSelectModule} from '@ng-select/ng-select';
import { InsertCurriculumComponent } from './pages/insert-curriculum/insert-curriculum.component';
import { FormCvComponent } from './components/form-cv/form-cv.component';
import { InputErrorsComponent } from './components/input-errors/input-errors.component';
import { InputFileComponent } from './components/input-file/input-file.component';
import {AngularEditorModule} from '@kolkov/angular-editor';
import { ToastComponent } from './components/toast/toast.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {LoadingBarHttpClientModule} from '@ngx-loading-bar/http-client';
import { BackComponent } from './components/back/back.component';
import { JobOfferDetailComponent } from './components/job-offer-detail/job-offer-detail.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import {HttpRequestInterceptor} from './services/http-request-interceptor.service';
import {NgxJsonLdModule} from 'ngx-json-ld';
import {JsonLdModule} from 'ngx-seo';
import {SelectDictionaryComponent} from './components/select-dictionary/select-dictionary.component';
import {NgcCookieConsentConfig, NgcCookieConsentModule} from 'ngx-cookieconsent';

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: 'localhost' // or 'your.domain.com' // it is mandatory to set a domain, for cookies to work properly (see https://goo.gl/S2Hy2A)
  },
  palette: {
    popup: {
      background: '#f47b1e'
    },
    button: {
      background: '#0ccc49',
    }
  },
  theme: 'edgeless',
  type: 'opt-out',
  content: {
    message: 'Questa piattaforma utilizza esclusivamente cookie tecnici necessari per il funzionamento del sito, per l\'utilizzo non serve il tuo consenso;\n' +
      'Cliccando “Accetta tutto” o "Rifiuta e chiudi" verranno installati solo i cookie tecnici. Per saperne di più consulta la nostra ' +
      '<a class=\"cc-link\" href="https://csfbusiness.it/cookies-policy.html">Informativa Cookies</a>',
    href: 'https://csfbusiness.it/cookies-policy.html',
    cookiePolicyLink: 'Cookie Policy',
    cookiePolicyHref: 'https://csfbusiness.it/cookies-policy.html',

    privacyPolicyLink: 'Privacy Policy',
    privacyPolicyHref: 'https://csfbusiness.it/cookies-policy.html',

    tosLink: 'Terms of Service',
    tosHref: 'https://csfbusiness.it/informativa-sulla-privacy.html',
    link: 'Informativa Privacy',
    allow: 'Accetta tutto',
    deny: 'Rifiuta e chiudi'
  }
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    NavbarComponent,
    HomeComponent,
    FormSearchComponent,
    OffersListComponent,
    FooterComponent,
    JobOffersComponent,
    InsertCurriculumComponent,
    FormCvComponent,
    InputErrorsComponent,
    InputFileComponent,
    ToastComponent,
    BackComponent,
    JobOfferDetailComponent,
    PaginationComponent,
    SelectDictionaryComponent
  ],
  imports: [
    NgcCookieConsentModule.forRoot(cookieConfig),
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    HttpClientModule,
    FontAwesomeModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    AngularEditorModule,
    NgbModule,
    LoadingBarHttpClientModule,
    NgxJsonLdModule,
    JsonLdModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
