import {Injectable} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class DictionaryModalService {

  constructor(private modalService: NgbModal) { }

  openModal(content: any): any{
    return this.modalService.open(content, {centered: true, size: 'xl'});
    }
}
