import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {tap} from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(private http: HttpClient) { }

  getAuthHeader(): HttpHeaders {
    if (localStorage.getItem('token')){
      return new HttpHeaders(
        {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      );
    }
    return new HttpHeaders();
  }

  get(path: string): Observable<any> {
    return this.http.get(this.getEnvironmentPath() + path, {headers: this.getAuthHeader()}).pipe(
      tap(response => response)
    );
  }
  post(path: string, request: any): Observable<any> {
      return this.http.post(this.getEnvironmentPath() + path, request, {headers: this.getAuthHeader()}).pipe(
        tap((response) => response)
      );
  }

  delete(path: string, id: number): Observable<any>{
      return this.http.delete(this.getEnvironmentPath() + path + '/' + id, {headers: this.getAuthHeader()}).pipe(
        tap((response) => response)
      );
  }

  patch(path: string, request: any, id: number): Observable<any>{
    return this.http.patch(this.getEnvironmentPath() + path + '/' + id, request, {headers: this.getAuthHeader()}).pipe(
      tap((response) => response)
    );
  }

  put(path: string, request: any, id: number): Observable<any> {
    return this.http.put(this.getEnvironmentPath() + path + '/' + id, request, {headers: this.getAuthHeader()}).pipe(
      tap((response) => response)
    );
  }

  private getEnvironmentPath(): string{
    return environment.api;
  }


}
