import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {DictionaryService} from './dictionary.service';
import {ContractType} from '../models/ContractType';

@Injectable({
  providedIn: 'root'
})
export class ContractTypeService extends DictionaryService<ContractType> {

  path = 'contract_types';
  constructor(http: HttpClient) {
    super(http);
  }
}
