<table class="table table-striped table-hover">
  <thead>
  <tr>
    <th scope="col">Titolo</th>
    <th scope="col">Data di pubblicazione</th>
    <th scope="col">Sede</th>
    <th scope="col">Area aziendale</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let jobOffer of paginatedResponse.data">
    <td><button class="btn btn-link" (click)="selectJobOffer(jobOffer)">{{jobOffer.name}}</button></td>
    <td>{{jobOffer.start_date | date: "dd-MM-YYYY"}}</td>
    <td>{{jobOffer.city?.province?.name}} / {{jobOffer.city?.name}}</td>
    <td>{{jobOffer.job_offer_area?.name}}</td>
  </tr>
  </tbody>
</table>
<div class="pe-4 pb-2">
  <app-pagination [paginatedResponse]="paginatedResponse" (pageChangeEvent)="updatePage($event)"></app-pagination>
</div>
