import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {HttpService} from './http.service';
import {JobOffer} from '../models/JobOffer';
import {BaseResponse} from '../models/respose/BaseResponse';
import {Order} from '../models/Order';
import {PaginatedDataResponse} from '../models/respose/PaginatedDataResponse';
import {PaginatedDataRequest} from '../models/request/PaginatedDataRequest';

@Injectable({
  providedIn: 'root'
})
export class JobOfferService extends HttpService {

  constructor(http: HttpClient) {
    super(http);
  }

  getAll(): Observable<BaseResponse<JobOffer[]>>{
    return this.get('job_offers/index');
  }

  getPaginatedList(entries: number,
                   page: number,
                   data?: JobOffer,
                   order?: Order): Observable<BaseResponse<PaginatedDataResponse<JobOffer>>>{
    const request = new PaginatedDataRequest<JobOffer>(entries, page, data, order);
    return this.post('job_offers', request).pipe(
      map(response => {
        return response;
      })
    );
  }

  register(request: JobOffer): Observable<BaseResponse<JobOffer>>{
    return this.post('job_offers/store', request);
  }

  getItem(id: number): Observable<BaseResponse<JobOffer>>{
    return this.get('job_offers/' + id);
  }
  deleteItem(id: number): Observable<BaseResponse<JobOffer>>{
    return this.delete('job_offers', id);
  }

  updateItem(request: JobOffer, id: number): Observable<BaseResponse<JobOffer>>{
    return this.put('job_offers', request, id);
  }
}
