import {Injectable} from '@angular/core';
import {HttpService} from './http.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {BaseResponse} from '../models/respose/BaseResponse';
import {Order} from '../models/Order';
import {PaginatedDataResponse} from '../models/respose/PaginatedDataResponse';
import {PaginatedDataRequest} from '../models/request/PaginatedDataRequest';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DictionaryService<T> extends HttpService {

  constructor(http: HttpClient) {
    super(http);
  }

  protected path = '';

  getAll(): Observable<BaseResponse<T[]>>{
    return this.get(this.path);
  }

  getPaginatedList(entries: number,
                   page: number,
                   data?: T,
                   order?: Order): Observable<BaseResponse<PaginatedDataResponse<T>>>{
    const request = new PaginatedDataRequest<T>(entries, page, data, order);
    return this.post(this.path, request).pipe(
      map(response => {
        return response;
      })
    );
  }

  register(request: T): Observable<BaseResponse<T>>{
    return this.post(this.path + '/store', request);
  }

  deleteItem(id: number): Observable<BaseResponse<T>>{
    return this.delete(this.path, id);
  }

  updateItem(request: T, id: number): Observable<BaseResponse<T>>{
    return this.put(this.path, request, id);
  }
}
