import {Gender} from '../models/Gender';

export class Genders{
    public static values: Gender[] = [
      {
        id: 1,
        name: 'Maschio'
      },
      {
        id: 2,
        name: 'Femmina'
      }
      ];
}
