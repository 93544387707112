import { Component, OnInit } from '@angular/core';
import {faBook, faGavel, faUserSecret, faPaw, faEye} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  faBook = faBook;
  faGavel = faGavel;
  faUserSecret = faUserSecret;
  faPaw = faPaw;
  faEye = faEye;
  constructor() { }

  ngOnInit(): void {
  }

}
