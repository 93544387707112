import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Province} from '../../models/Province';
import {City} from '../../models/City';
import {Subject} from '../../models/Subject';
import {Job} from '../../models/Job';
import {Task} from '../../models/Task';
import {JobOffer} from '../../models/JobOffer';
import {StudyCertificate} from '../../models/StudyCertificate';
import {CurriculumService} from '../../services/curriculum.service';
import {ProvinceService} from '../../services/province.service';
import {StudyCertificateService} from '../../services/study-certificate.service';
import {SubjectService} from '../../services/subject.service';
import {CityService} from '../../services/city.service';
import {TaskService} from '../../services/task.service';
import {ExperienceYearService} from '../../services/experience-year.service';
import {JobService} from '../../services/job.service';
import {JobOfferService} from '../../services/jobOffer.service';
import {environment} from '../../../environments/environment';
import {FileConstants} from '../../constants/FileConstants';
import {AngularEditorConfig} from '@kolkov/angular-editor';
import {CurriculumRequest} from '../../models/request/CurriculumRequest';
import {Nation} from '../../models/Nation';
import {NationService} from '../../services/nation.service';
import {Tag} from '../../models/Tag';
import {TagService} from '../../services/tag.service';
import {Genders} from '../../constants/Genders';
import {Gender} from '../../models/Gender';

@Component({
  selector: 'app-form-cv',
  templateUrl: './form-cv.component.html',
  styleUrls: ['./form-cv.component.scss']
})
export class FormCvComponent implements OnInit {
  @Input() jobOffer: JobOffer;
  @Output() saveCurriculum = new EventEmitter<CurriculumRequest>();
  public form: FormGroup;
  public provinces: Province[] = [];
  public cities: City[] = [];
  public studyCertificates: StudyCertificate[] = [];
  public subjects: Subject[] = [];
  public tasks: Task[] = [];
  public jobs: Job[] = [];
  public nations: Nation[] = [];
  public max_file_size_kb = environment.max_file_size_kb;
  public imageFileTypes = FileConstants.imageExtensions;
  public pdfFileTypes = FileConstants.pdfExtension;
  public buttonDisabled = true;

  public checkboxTags: Tag[] = [];
  public checkboxTagsToSend: Tag[] = [];

  public genders: Gender[] = Genders.values;

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Inserisci il testo...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      [
        'subscript',
        'superscript',
        'indent',
        'outdent',
        'heading',
        'fontName',
        'backgroundColor',
        'link',
        'unlink',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
      ]
    ],
  };

  constructor(
    private fb: FormBuilder,
    private curriculumService: CurriculumService,
    private provinceServices: ProvinceService,
    private citiesService: CityService,
    private studyCertificatesService: StudyCertificateService,
    private subjectService: SubjectService,
    private taskService: TaskService,
    private experienceYearService: ExperienceYearService,
    private jobService: JobService,
    private jobOfferService: JobOfferService,
    private tagService: TagService,
    private nationService: NationService
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      id: [null],
      first_name: ['', [Validators.required]],
      last_name: ['', [Validators.required]],
      birth_date: ['', [Validators.required]],
      nation_id: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      province_id: ['', Validators.required],
      city_id: [''],
      mobile_number: ['', [Validators.required, Validators.pattern(/^\d{9,}$/)]],
      study_certificate_id: ['', [Validators.required]],
      subject_id: [''],
      curriculum: ['', [Validators.required]],
      picture: [''],
      job_offer_id: [this.jobOffer?.id],
      cover_letter: [''],
      work_experiences: this.fb.array([]),
      gender_id: ['', [Validators.required]],
      fiscal_code: ['', [Validators.required]]
    });
    this.updateCheckboxes();
  }
  onSubmit(fg: FormGroup): void{
    fg.patchValue({job_offer_id: this.jobOffer.id});
    fg.value.tags = this.checkboxTagsToSend;
    if (fg.valid){
      this.saveCurriculum.emit(fg.value);
    }
    this.form.markAllAsTouched();
  }
  getInvalidClass(element: string): string{
    // @ts-ignore
    return (this.form.get(element).invalid && (this.form.get(element).dirty || this.form.get(element).touched)) ? 'is-invalid' : '';
  }

  updateProvinces(): void {
    if (this.provinces.length === 0) {
      this.provinceServices.getAll().subscribe(result => {
        this.provinces = result.data;
      });
    }
  }
  updateCities(province_id: number): void {
    this.form.controls.city_id.setValue('');
    this.citiesService.getAll(province_id).subscribe(result => {
      this.cities = result.data;
    });
  }
  updateStudyCertificates(): void{
    if (this.studyCertificates.length === 0){
      this.studyCertificatesService.getAll().subscribe( result => {this.studyCertificates = result.data; });
    }
  }
  updateSubjects(): void{
    if (this.subjects.length === 0){
      this.subjectService.getAll().subscribe( result => {this.subjects = result.data; });
    }
  }
  updateNations(): void {
    if (this.nations.length === 0) {
      this.nationService.getAll().subscribe(result => {
        this.nations = result.data;
      });
    }
  }
  getTextSize(kb_size: number): string{
    if (kb_size > 1000){
      return kb_size / 1000 + ' mb';
    }
    return kb_size + ' kb';
  }

  updateCheckboxes(): void {
    if (this.checkboxTags.length === 0) {
      this.tagService.getCheckboxTags().subscribe(result => {
        this.checkboxTags = result.data;
      });
    }
  }

  setCheckboxes(event: Event | any): void {
    const value = event.target.checked;
    const name = event.target.name.split('_')[1];
    // tslint:disable-next-line:radix
    const tagId = parseInt(name);

    if (value === true){
      const contains = this.checkboxTagsToSend.find(t => t.id === tagId);
      if (!contains){
        const tag = this.checkboxTags.find(t => t.id === tagId);
        if (tag) { this.checkboxTagsToSend.push(tag); }
      }
    }
    else if (value === false) {
      const tag = this.checkboxTagsToSend.find(t => t.id === tagId);
      if (tag) {
        const index = this.checkboxTagsToSend.indexOf(tag);
        if (index > -1) { this.checkboxTagsToSend.splice(index, 1); }
      }
    }
  }
  get work_experiences(): FormArray{
    return this.form.controls.work_experiences as FormArray;
  }
  addWorkExperience(): void{
    const workExperienceForm = this.fb.group({
      id: [null],
      company: ['', Validators.required],
      task: ['', Validators.required],
      start_date: ['', Validators.required],
      end_date: ['', Validators.required],
      contract_type_id: ['', Validators.required]
    });
    this.work_experiences.push(workExperienceForm);
  }
  deleteWorkExperience(index: number): void{
    this.work_experiences.removeAt(index);
  }

  getWorkExperienceForm(index: number): FormGroup{
    return this.work_experiences.at(index) as FormGroup;
  }
}
