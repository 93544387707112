import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {faAngleLeft} from '@fortawesome/free-solid-svg-icons/faAngleLeft';
@Component({
  selector: 'app-back',
  templateUrl: './back.component.html',
  styleUrls: ['./back.component.scss']
})
export class BackComponent implements OnInit {
  @Output() backClicked = new EventEmitter<void>();
  faAngleLeft = faAngleLeft;
  constructor() { }

  ngOnInit(): void {
  }
   goBack(): void{
    this.backClicked.emit();
   }

}
