import {DirectionType} from '../enum/DirectionType';

export class Order{
  constructor(attribute: string, direction: DirectionType) {
    this.attribute = attribute;
    this.direction = direction;
  }
    attribute = '';
    direction: DirectionType = DirectionType.asc;
}
