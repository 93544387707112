import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {HttpService} from './http.service';
import {BaseResponse} from '../models/respose/BaseResponse';
import {Nation} from '../models/Nation';
import {PaginatedDataResponse} from '../models/respose/PaginatedDataResponse';
import {PaginatedDataRequest} from '../models/request/PaginatedDataRequest';
import {Order} from '../models/Order';

@Injectable({
  providedIn: 'root'
})
export class NationService extends HttpService {

  constructor(http: HttpClient) {
    super(http);
  }

  getAll(): Observable<BaseResponse<Nation[]>>{
    return this.get('nations');
  }

  getPaginatedList(entries: number, page: number, data?: Nation, order?: Order): Observable<BaseResponse<PaginatedDataResponse<Nation>>>{
    const request = new PaginatedDataRequest<Nation>(entries, page, data, order);
    return this.post('nations', request).pipe(
      map(response => {
        return response;
      })
    );
  }

  register(request: Nation): Observable<BaseResponse<Nation>>{
    return this.post('nations/store', request);
  }

  deleteItem(id: number): Observable<BaseResponse<Nation>>{
    return this.delete('nations', id);
  }

  updateItem(request: Nation, id: number): Observable<BaseResponse<Nation>>{
    return this.put('nations', request, id);
  }
}
