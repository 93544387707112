<div *ngIf="element?.invalid && (element?.dirty || element?.touched)" class="text-danger small">
  <div *ngIf="element?.errors?.required">
    Campo obbligatorio
  </div>
  <div *ngIf="element?.errors?.minlength">
    La lunghezza minima di questo campo è {{element?.errors?.minlength.requiredLength}} caratteri.
  </div>
  <div *ngIf="element?.errors?.maxlength">
    La lunghezza minima di questo campo è {{element?.errors?.maxlength.requiredLength}} caratteri.
  </div>
  <div *ngIf="element?.errors?.email">
    Formato email non corretto.
  </div>
  <div *ngIf="element?.errors?.mustMatch">
    I due campi devono coincidere
  </div>
  <div *ngIf="element?.errors?.onlyNumbers">
    Questo campo non può contenere lettere
  </div>
</div>
