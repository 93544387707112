import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {HttpService} from './http.service';
import {BaseResponse} from '../models/respose/BaseResponse';
import {StudyCertificate} from '../models/StudyCertificate';
@Injectable({
  providedIn: 'root'
})
export class StudyCertificateService extends HttpService {

  constructor(http: HttpClient) {
    super(http);
  }

  getAll(): Observable<BaseResponse<StudyCertificate[]>>{
    return this.get('study_certificates/index');
  }

}
